/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/jsx-props-no-spreading */
import { withRouter } from 'react-router-dom'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { DISPLAY_MODE, PAGES, ROLES } from '../../constant'
import { isEmbeddedView, isSmartView , encounterConditionDisplayVersion, encounterConditionClinicalAdminDisplayVersion} from '../common/currentUserUtils'
import CdiEncounter from './cdi/cdiEncounter'
import ClinicalAdminEncounter from './clinicalAdmin/clinicalAdminEncounter'
import CoderEncounter from './coder/CoderEncounter'
import PhysicianEncounter from './physician/physicianEncounter'
import PhysicianEncounterTwo from './physician/physicianEncounterTwo'
import ClinicalAdminEncounterTwo from './clinicalAdmin/clinicalAdminEncounterTwo'
import If from '../common/if'
import './detail.css'

/**
 * Provides the top-level encounter detail component. Determines which view of
 * the encounter detail to display based on the current user role.
 */

type Props = {
  currentUser: any
  match: any
}

function Encounter(props: Props): JSX.Element {
  const { currentUser, match } = props

  const [ encounterId ] = useState<string>(match.params.id)

  function onEncounterNav(id: string): void {
    window.open(`${PAGES.ENCOUNTER_DETAIL}${id}`, '_blank')
  }

  const userRole = currentUser.type
  const conditionGroups = match.params.conditions as string[]

  const mode = isSmartView(match.path)
    ? DISPLAY_MODE.SMART
    : isEmbeddedView(match.path) ? DISPLAY_MODE.EMBEDDED : null

  const cdiAndCoderProps = {
    key: encounterId,
    mode,
    encounterId,
    onEncounterNav,
    currentUser
  }

  switch (userRole) {
    case ROLES.CDI:
    case ROLES.CDI_RESTRICTED:
      return <CdiEncounter { ...cdiAndCoderProps } />
    // break
    case ROLES.CLINICAL_ADMIN:
      return (
      <>
        <If isTrue={ encounterConditionClinicalAdminDisplayVersion(currentUser) !== '2' }>
          <ClinicalAdminEncounter
            key={ encounterId }
            mode={ mode }
            encounterId={ encounterId }
            conditionGroups={ conditionGroups }
            onEncounterNav={ onEncounterNav }
          />
        </If>
        <If isTrue={ encounterConditionClinicalAdminDisplayVersion(currentUser) === '2' }>
          <ClinicalAdminEncounterTwo
            mode={ mode }
            encounterId={ encounterId }
            currentUser={ currentUser }
            onEncounterNav={ onEncounterNav }
          />
        </If>
        </>
      )
    case ROLES.CODER:
      return <CoderEncounter { ...cdiAndCoderProps } />
    // break
    case ROLES.PHYSICIAN:
      return (
        <>
          <If isTrue={ encounterConditionDisplayVersion(currentUser) !== '1' }>
            <PhysicianEncounterTwo
              mode={ mode }
              encounterId={ encounterId }
              currentUser={ currentUser }
              onEncounterNav={ onEncounterNav }
            />
          </If>
          <If isTrue={ encounterConditionDisplayVersion(currentUser) === '1' }>
            <PhysicianEncounter
              key={ encounterId }
              mode={ mode }
              encounterId={ encounterId }
              conditionGroups={ conditionGroups }
              onEncounterNav={ onEncounterNav }
            />
          </If>
        </>
      )
    default:
      return <></>
  }
}

export default withRouter(
  connect(
    (state: any) => ({
      currentUser: state.user
    }),
    {}
  )(Encounter)
)
