import { CONDITION, ENCOUNTER, LOCK, REVIEW, ROLES } from '../../constant'
import { ConditionType, Patient, StatusCode } from '../patient/patient'

export type ObjectValues<T> = T[keyof T]

export type CronJob = {
  className: string
  cronExpression: string | null
  description: string | null
  executionType: string
  groupName: string
  id: string
  interval: number | null
  intervalType: string | null
  metaJson: CronJobProperties | null
  name: string
  repeatCount: number | null
  statusCode: string
  tenantId: string
  triggerName: string
  version: number
}

export type CronJobProperties = {
  properties: CronJobParameter[]
}

export type CronJobParameter = {
  description: string
  propertyKey: string
  propertyName: string
  propertyType: string
}

export type AppointmentlistFilter = {
  mrn?: any
  start?: any
  firstName?: any
  lastName?: any
  birthDate?: any
  practitionerId?: any
  defaultPatientOffshoreFilter?: any
}

export type PatientListFilter = {
  mrn?: any
  firstName?: any
  lastName?: any
  birthDate?: any
  homePhone?: any
  email?: any
  defaultPatientOffshoreFilter?: any,
  patientEncounterReviewStatus?: any,
  patientRegion?: any
}

type Location = {
  pathname: string
  search: string
  hash: string
}

type History = {
  length: number
  action: string
  location: Location
}

export type Role = {
  id: string
  name: keyof typeof ROLES
  description: string
  statusCode: string
  creationDate: Date
  modificationDate: Date
  createdById: string
  modifiedById: string
  version: number
}

export type Property = {
  propertyKey: string
  propertyValue: keyof typeof CONDITION.TYPE
}

export type TenantProperty = Property & {
  id: string
  tenantId: string
}

export type WorklistFilters = {
  start?: any
  firstName?: any
  lastName?: any
  birthDate?: any
  patientOrgId?: any
  appointmentType?: any
  practitionerId?: any
  view?: any
  encounterSort?: any
  patientRegion?: any
  vbcProgram?: any
  defaultPatientRegion?: any
  defaultPatientOrgId?: any
  defaultPatientOffshoreFilter?: any
  mrn?: any
  potentialConditionCount?: any
  specialty?: any
}

export type User = {
  alias: string
  authType: string
  qrCodeUuid: string
  authMessage: string | null
  authAttempts: number
  id: string
  isAuthenticated: boolean
  tenantId: string
  userId: string
  roleName: string
  roles: Role[]
  tenantProperties: TenantProperty[]
  token: string
  type: keyof typeof ROLES
  username: string
  password: string
  userProperties: Property[]
  valid: boolean
  authStatus: string
  worklistFilters: WorklistFilters[]
  appointmentlistFilters: AppointmentlistFilter[]
  patientListFilters: PatientListFilter[]
}

export function isUser(data: User | MfaRequest): data is User {
  // look for properties that only appear on an instance of current user
  return (data as User).id !== undefined &&
    (data as User).username !== undefined
}

export type MfaRequest = {
  status: string,
  result: string,
  mfaType: string,
  mfaEmail: string,
  header: string,
  qrCodeUuid: string
}

export function isMfaRequest(data: User | MfaRequest): data is MfaRequest {
  // look for properties that only appear on an instance of MFA request
  return (data as MfaRequest).mfaType !== undefined &&
    (data as MfaRequest).mfaEmail !== undefined
}

export type Props = {
  active: string
  history: History
  currentUser: User
}

export enum LabelType {
  Suspected = 'suspected'
}

type UUID = string
type BigDecimal = string

export type PractitionerEncounter = {
  id: UUID | null
  lastName: string | null
  firstName: string | null
}

export type EncounterReviewer = {
  id: UUID
  tenantId: UUID
  userId: UUID
  username: string | null
  firstName: string | null
  middleName: string | null
  lastName: string | null
}

export type EncounterReview = {
  id: UUID
  tenantId: UUID
  reviewCode: keyof typeof REVIEW.CODE | null
  priority: number
  version: number
  queryStatusCode: string | null
  reviewer: EncounterReviewer | null
}

export type EncounterLock = {
  id: UUID
  tenantId: UUID
  encounterId: UUID
  statusCode: keyof typeof LOCK.STATUS
  creationDate: Date
  createdById: UUID
  createdByUsername: string
  patientLockCount: number
} | null

export type Encounter = {
  id?: UUID
  tenantId?: UUID
  identifier?: string
  appointmentType?: string | null // TODO?: Make Enum
  apponumbermentId?: UUID | null
  apponumbermentType?: string | null
  status?: ObjectValues<typeof ENCOUNTER.STATUS> | null
  code?: string | null
  encounterTypeCode?: string | null
  description?: string | null
  cost?: BigDecimal | null
  start?: Date | null
  end?: Date | null
  reasonCode?: string | null
  reasonDescription?: string | null
  ingestDate?: Date | null
  coderQueryOutstandingDate?: Date | null
  patient?: Patient
  practitioner?: PractitionerEncounter
  review?: EncounterReview | null
  lock?: EncounterLock | null
}

export type EncounterCopy = {
  id: UUID
  tenantId: UUID
  encounterId: UUID
  previousEncounterId: UUID | null
  statusCode: string | null
  creationDate: Date | null
  modificationDate: Date | null
  createdById: UUID | null
  createdByUsername: string | null
  modifiedById: UUID | null
  modifiedByUsername: string | null
  version: number | null
}

export type EncounterDetail = {
  encounter?: Encounter
  raf?: PatientRafRow[]
  conditions?: EncounterConditionDisplay[]
  links?: EncounterLink
  notes?: EncounterNoteRow[] | null
  previous?: EncounterConditionDisplay[] | null
  // TODO: This really shouldn't belong here it is also a property on the encounter
  encounterTypeCode?: string | null
}

export type PatientRafRow = {
  id: UUID
  tenantId: UUID
  subjectId: UUID
  statusCode: string
  rafCode: string
  rafModel: string
  score: BigDecimal
  periodStart: Date
  periodEnd: Date | null
  note: string | null
  metaJson: JSON | null
  version: number
}

export type EncounterConditionCdiTagsRow = {
  id: UUID
  tenantId: UUID
  encounterConditionId: UUID
  netNewStatusCode: string | null
  knownPotentialStatusCode: string | null
  expectedDiagnosis: string | null
}

export type EncounterConditionCoderTagsRow = {
  id?: UUID
  tenantId?: UUID
  encounterConditionId?: UUID
  queryStatusCode?: string | null
  coderTags?: string | null
  onClaimStatusCode?: string | null
  reportableStatusCode?: string | null
  reportableHeaderCode?: string | null
  reportableReason?: string | null
  reportableText?: string | null
  activityMetStatusCode?: string | null
  conditionDiagnosisCode?: string | null
  queryCreationDate?: Date | null
  queryResponseDate?: Date | null
  queryCreationUserId?: UUID | null
  queryCreationUsername?: string | null
  queryResponseUserId?: UUID | null
  queryResponseUsername?: string | null
}

export type EncounterConditionPractitionerTagsRow = {
  id: UUID
  tenantId: UUID
  encounterConditionId: UUID
  practitionerResponseCode: string | null
  creationDate: Date
  modificationDate: Date
  createdById: UUID
  modifiedById: UUID
}

export type RuleInputJson = {
  id: string
  ruleInternalId: string | null | undefined
  claimUseEnum: string | null
  type: string
  title: string
  reason: string
  hccCode: string
  subtype: string | null
  icd10List: string[]
  hccVersion: string
  icd10Count: string | null
  description: string
  ruleFilterId: string | null
  addressedDate: string | null
  conditionCode: string | null
  hccDescription: string
  conditionStatus: string | null
  medicationTitle: string | null
  targetSourceCode: string | null
}

export type EncounterConditionRuleRow = {
  id: UUID
  encounterConditionId: UUID
  ruleId: UUID | null
  ruleInputJson: RuleInputJson | null
  title: string
  reason: string | null
  content: string | null
  statusCode: string
  version: number
  creationDate: Date
  modificationDate: Date
  createdById: UUID
  modifiedById: UUID
  targetId: string | null
  targetType: string | null
  targetSubtypeCode: string | null // "EXTERNAL" MAKE ENUM?
  targetSourceCode: string // "EXTERNAL" MAKE ENUM?
}

export type EncounterNoteRow = {
  id: UUID
  tenantId: UUID
  createdById: UUID
  createdByUserName: string | null
  recipientUserId: UUID | null
  recipientUserName: string | null
  encounterId: UUID
  encounterConditionId: UUID | null
  noteSubject: string | null
  noteBody: string
  creationDate: Date
  noteStatusCode: string
  noteReasonCode: string
}

export type EncounterConditionConfidenceRow = {
  id: UUID
  tenantId: UUID
  encounterConditionId: UUID
  icd10Count: number | null
  icd10ConfidenceRaw: BigDecimal | null
  icd10ConfidenceComposite: BigDecimal | number | null
  hccCount: number | null
  hccConfidence: BigDecimal | number | null
  isSuspect: boolean | null
  suspectReason: string | null
}

export type EncounterConditionEncounterHolder = {
  id?: UUID
  startDate?: Date | null
}

export type EncounterConditionDisplay = {
  addressedDate: Date | null
  checkMark: any | null
  cdiTags: EncounterConditionCdiTagsRow | null
  coderTags: EncounterConditionCoderTagsRow | null
  comments: string | null
  conditionCode: string
  conditionStatus: ConditionType
  confidence: EncounterConditionConfidenceRow | null
  content: string | null
  createdById: UUID
  creationDate: Date
  encounter: EncounterConditionEncounterHolder
  hccCode: string | null
  hccDescription: string | null
  hccVersion: string
  icd10CodeJson: string[] | null
  id: UUID
  modificationDate: Date
  modifiedById: UUID
  notes: EncounterNoteRow[] | null
  practitionerTags: EncounterConditionPractitionerTagsRow | null
  priority: BigDecimal | number
  reason: string | null
  reasonCode: string | null
  ruleFilterId: UUID | null
  rules: EncounterConditionRuleRow[] | null
  statusCode: StatusCode
  tenantId: UUID
  title: string
  version: number
}

export enum TagStatus {
  NotEvaluated = 'NOT_EVALUATED',
  Removed = 'REMOVED',
  Dismissed = 'DISMISSED',
  Accept = 'ACCEPT',
  NotClicked = 'NOT_CLICKED'
}

export type EncounterConditionDisplayModified = {
  conditionStatus: ConditionType
  encounter: EncounterConditionEncounterHolder
  icd10CodeJson: string[] | null
  id: UUID
  title: string
  content: string | null
  hccCode: string | null
  // Properties we are adding for the UI
  hccConfidence: number
  description: string[]
  tagOptions: ButtonOptions[]
  isAddressed: boolean
  isExternal: boolean
  isKnown: boolean
  isRequired: boolean
  isPotential: boolean
  isKnownAddressed: boolean
  isDefaultState: boolean
  practitionerResponseCode: TagStatus | null
  // Removed Properties for a Cleaner UI
  // reason: string | null
  // version: number
  // addressedDate: Date | null
  // cdiTags: EncounterConditionCdiTagsRow | null
  // coderTags: EncounterConditionCoderTagsRow | null
  // checkMark: any | null
  // priority: BigDecimal
  // modificationDate: Date
  // ruleFilterId: UUID | null
  // statusCode: string
  // reasonCode: string | null
  // practitionerTags: EncounterConditionPractitionerTagsRow | null
  // tenantId: UUID
  // confidence: EncounterConditionConfidenceRow | null
  // rules: EncounterConditionRuleRow[] | null
  // comments: string | null
  // createdById: UUID
  // creationDate: Date
  // notes: EncounterNoteRow[] | null
  // modifiedById: UUID
  // conditionCode: string
  // hccDescription: string | null
  // hccVersion: string
}


export type EncounterLink = {
  prev: UUID | null
  next: UUID | null
}

export type PatientType = {
  encounter: Encounter
  raf: PatientRafRow[]
  conditions: EncounterConditionDisplay[]
  links: EncounterLink
  notes: EncounterNoteRow[] | null
  previous: EncounterConditionDisplay[] | null
}

export type Addressed = {
  hccCode: null
  hccDescription: null
  conditions: EncounterConditionDisplay[]
  addressed: boolean
}

export enum GroupingTitle {
  Activities = 'Activities',
  KnownConditions = 'Known Conditions',
  PotentialOpportunities = 'Potential Opportunities',
  ClinicalConditions = 'Clinical Conditions',
  PreviousEncounters = 'Previous Encounters',
  Default = ''
}

export type Group = {
  hccCode: string | null
  hccDescription: string | null
  conditions: EncounterConditionDisplay[]
  addressed: boolean
}

export type ConditionGroup = {
  title: GroupingTitle
  conditions: Group[]
}

export type ButtonOptions = {
  id: string
  value: string
  label: string
  text: string
}

export type DisplayConditions = {
  title: string
  conditions: Group[]
  hasInsightsToShow?: boolean
}
